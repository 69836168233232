import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { TourDateComponent } from './components/tour-date/tour-date.component';
import { TourdetailPreeventComponent } from './components/tourdetail-preevent/tourdetail-preevent.component';
import { TourdetailPosteventComponent } from './components/tourdetail-postevent/tourdetail-postevent.component';
import { FacultyComponent } from './components/faculty/faculty.component';
import { FacultyDetailsComponent } from './components/faculty-details/faculty-details.component';
import { RulesAndRegulationsComponent } from './components/rules-and-regulations/rules-and-regulations.component';
import { LiveStreamComponent } from './components/live-stream/live-stream.component';
import { DownloadMediaComponent } from './components/download-media/download-media.component';
import { ContactComponent } from './components/contact/contact.component';
import { ExecutiveTeamComponent } from './components/executive-team/executive-team.component';
import { JoinOurTeamComponent } from './components/join-our-team/join-our-team.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { DancerVerificationComponent } from './components/dancer-verification/dancer-verification.component';
import { ImportantComponent } from './components/important/important.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { HiringComponent } from './components/hiring/hiring.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'tour-dates', component: TourDateComponent },
  { path: 'tourdetails-preevent/:eventseoname', component: TourdetailPreeventComponent },
  { path: 'tourdetails-postevent/:eventseoname', component: TourdetailPosteventComponent },
  { path: 'tour-dates/:eventseoname', component: TourdetailPreeventComponent },
  //{ path: 'tour-dates/:eventseoname', component: TourdetailPosteventComponent },
  //{ path: 'faculty', component: FacultyComponent },
  { path: 'faculty-detail/:seoname', component: FacultyDetailsComponent },
  { path: 'rules-and-regulations', component: RulesAndRegulationsComponent },
  { path: 'groove-live', component: LiveStreamComponent },
  { path: 'dancer-registration', component: DownloadMediaComponent },
  { path: 'contact', component: ContactComponent },
  //{ path: 'executive-team', component: ExecutiveTeamComponent },
  { path: 'join-our-team', component: JoinOurTeamComponent },
  { path: 'join-us', component: JoinUsComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'dancer-verification/verify/:VerificationId', component: DancerVerificationComponent },
  //{ path: 'important', component: ImportantComponent },
  /*{ path: 'news', component: BlogComponent},*/
  { path: 'news-detail/:seoname', component: BlogDetailsComponent },
  { path: 'joinourteam', component: HiringComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
